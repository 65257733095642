.live-projects-title,.pick-master-scene{
    color: white !important;
}

.publish-button{
    align-self: unset !important;
    margin: 0 !important;
    cursor: pointer;
}

.create-newproject-name, .upload-360-title{
    color: white !important;
    font-weight: bold !important;
}
.next-button-newproject{
    color: white !important;
    font-size: 16px !important;
    margin-top: -20px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
}


.nothing-published-title{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  min-height: 260px;
}

.MuiTypography-root-83{
   font-family: 'IBM Plex Sans', sans-serif !important;
}
