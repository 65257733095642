canvas{
    width: 100% !important;
    height: 100% !important;
}

.imagename{
    font-size: 18px;
    margin-top: 2px;
    padding-left: 15px;
    width: fit-content;
    min-width: 45%;
    /* background-color: #FFCE01 */
}