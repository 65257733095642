body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

/* .nav {
    height: 130px;
    background-color: #ffffff;
  } */

.uploadGroup {
  display: flex;
  height: 40px;
  width: 120px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadBtn {
  width: 16px;
  margin-left: 16px;
}

.gallery {
  width: 1024px;
  height: 540px;
  background-color: white;
  margin: 0 auto;
  margin-top: 31px;
  border-radius: 6px;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e272e;
  border-radius: 6px 6px 0 0;
}

.gallery-header h1 {
  color: #ffffff;
  font-size: 22px;
  padding-left: 30px;
  margin: 14px 0 12px 0;
}

.gallery-header a {
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  padding-right: 30px;
}

.inner-box {
  display: flex;
  height: 393px;
  margin: 40px 62px;
  border: 1px solid #1e272e;
  border-radius: 6px;
}

.gallery-sidebar {
  display: flex;
  background-color: #1e272e;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px 0 0 4px ;
}

.gallery-sidebar input {
  background-color: white;
}

.gallery-sidebar p {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding-bottom: 13px;
}

.react-fine-uploader-file-input-container {
  cursor: pointer;
  background-color: white;
  margin: 27px 30px;
  border-radius: 5px;
  color: #1e272e;
  font-weight: 600;
}

.gallery-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: auto;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 22px;
  width: 140px;
}

.react-fine-uploader-cancel-button,
.react-fine-uploader-delete-button {
  border: 0px;
  display: flex;
  align-self: flex-end;
  width: 14px;
  padding: 0;
  justify-content: flex-end;
  outline: none;
}

.react-fine-uploader-cancel-button img,
.react-fine-uploader-delete-button img {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.thumbnail input {
  font-size: 14px;
  outline: none;
  border: 1px solid #1e272e;
  padding: 6px 7px;
  font-weight: 500;
}

.thumbnail-img {
  width: 140px;
  height: 80px;
}

.thumbnail-img img {
  width: 140px;
  height: 80px;
  object-fit: cover;
}

.nextIcon{
  display: flex;
  align-items: center;
}

.nextIcon > p{
  font-size: 16px;
  padding-right: 15px;
  font-weight: 600;
  margin: 0;
}

