.ui.secondary.pointing.menu .item {
  border-bottom: 4px solid transparent;
}

.ui.secondary.pointing.menu .item.active,
.ui.secondary.pointing.menu .active.item:hover {
  border-bottom: 4px solid #ffce01;
}

.bottom-tray-title {
  font-size: 14px !important;
  color: rgba(30, 39, 46, 1) !important;
  font-weight: 600 !important;
  /* font-family: "IBM Plex Sans semi" */
}

.list-no-hotspots {
  margin-left: 85% !important;
}
.hotspot-icon-bottom {
  margin-right: 12px !important;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.cardd {
  flex: 0 0 auto;
  width: 200px;
  height: 150px;
}

.MuiPaper-rounded-135 {
  border-radius: 0 !important;
}
