.live-projects-title,.pick-master-scene{
    color: white !important;
}

.publish-button{
    align-self: unset !important;
    margin: 0 !important;
    cursor: pointer;
}

.create-newproject-name, .upload-360-title{
    color: white !important;
    font-weight: bold !important;
}
.next-button-newproject{
    color: white !important;
    font-size: 16px !important;
    margin-top: -20px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
}


.nothing-published-title{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  min-height: 260px;
}

.MuiTypography-root-83{
   font-family: 'IBM Plex Sans', sans-serif !important;
}

span {
    font-size: 14px;
    font-weight: bolder;
    /* font-family: 'Roboto', sans-serif; */
}
.activation-page-width-height{
    width: 100vw;
    height: 100vh;
    display: table;
}
.activation-content-align{
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}
.activation-font-head{
    font-size: 24;
    font-weight: 600;
}
.activation-font{
    margin-top: 12;
    font-size: 16;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

/* .nav {
    height: 130px;
    background-color: #ffffff;
  } */

.uploadGroup {
  display: flex;
  height: 40px;
  width: 120px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadBtn {
  width: 16px;
  margin-left: 16px;
}

.gallery {
  width: 1024px;
  height: 540px;
  background-color: white;
  margin: 0 auto;
  margin-top: 31px;
  border-radius: 6px;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e272e;
  border-radius: 6px 6px 0 0;
}

.gallery-header h1 {
  color: #ffffff;
  font-size: 22px;
  padding-left: 30px;
  margin: 14px 0 12px 0;
}

.gallery-header a {
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  padding-right: 30px;
}

.inner-box {
  display: flex;
  height: 393px;
  margin: 40px 62px;
  border: 1px solid #1e272e;
  border-radius: 6px;
}

.gallery-sidebar {
  display: flex;
  background-color: #1e272e;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px 0 0 4px ;
}

.gallery-sidebar input {
  background-color: white;
}

.gallery-sidebar p {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding-bottom: 13px;
}

.react-fine-uploader-file-input-container {
  cursor: pointer;
  background-color: white;
  margin: 27px 30px;
  border-radius: 5px;
  color: #1e272e;
  font-weight: 600;
}

.gallery-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: auto;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 22px;
  width: 140px;
}

.react-fine-uploader-cancel-button,
.react-fine-uploader-delete-button {
  border: 0px;
  display: flex;
  align-self: flex-end;
  width: 14px;
  padding: 0;
  justify-content: flex-end;
  outline: none;
}

.react-fine-uploader-cancel-button img,
.react-fine-uploader-delete-button img {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.thumbnail input {
  font-size: 14px;
  outline: none;
  border: 1px solid #1e272e;
  padding: 6px 7px;
  font-weight: 500;
}

.thumbnail-img {
  width: 140px;
  height: 80px;
}

.thumbnail-img img {
  width: 140px;
  height: 80px;
  object-fit: cover;
}

.nextIcon{
  display: flex;
  align-items: center;
}

.nextIcon > p{
  font-size: 16px;
  padding-right: 15px;
  font-weight: 600;
  margin: 0;
}


canvas{
    width: 100% !important;
    height: 100% !important;
}

.imagename{
    font-size: 18px;
    margin-top: 2px;
    padding-left: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 45%;
    /* background-color: #FFCE01 */
}
* {
  box-sizing: border-box;
}

.category-card {
  position: absolute;
  width: 70vw;
  height: 62vh;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #e2e6ea;
  border-radius: 5px 5px 0 0;
}

.category-card-header {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  background-color: black;
  padding: 15px 0;
  font-size: 20px;
  border-radius: 5px 5px 0 0;
}

.category-thumbnail-group {
  display: flex;
  padding: 0 70px;
  padding-top: 91px;
  /* padding-bottom: 85px; */
  flex-wrap: wrap;
  height: inherit;
  overflow: hidden;
  overflow-y: scroll;
  align-content: flex-start;
}

.category-addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 200px;
  border-radius: 4px;
  background-color: black;
  cursor: pointer;
  border: none;
}

.category-addBtn img {
  display: block;
}

.category-thumbnail {
  /* display: flex; */
  position: relative;
  height: 100px;
  width: 200px;
  border-radius: 4px;
  background-color: lightgrey;
  margin-left: 24px;
  margin-bottom: 28px;
  align-items: flex-end;
}

.category-thumbnail > img {
  object-fit: cover;
  width: inherit;
  height: inherit;
}

.category-thumbnail-group > *:nth-child(4n + 1) {
  margin-left: 0;
}

.thumbnail-tag {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 14px;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.thumbnail-tag p {
  margin: 0;
  font-weight: 600;
  color: white;
}

.thumbnail-tag button {
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.category-card-footer {
  display: flex;
  background-color: white;
  justify-content: space-between;
  padding: 20px 100px;
  align-items: center;
  border-radius: 0 0 5px 5px;
}

.category-card-footer p {
  margin: 0;
}

.category-card-footer button {
  background-color: black;
  color: white;
  font-weight: 600;
  padding: 8px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.modal-card {
  position: relative;
  width: 600px;
  max-height: 70vh;
  background-color: white;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-header {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  align-items: center;
  background-color: white;
  z-index: 9;
  border-radius: 4px 4px 0 0;
}

.modal-header h2 {
  font-size: 20px;
  padding: 18px 0 9px 0;
  margin: 0;
}

.modal-header button {
  display: block;
  position: absolute;
  border: none;
  margin: 0;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  outline: none;
}

.modal-header .backBtn {
  left: 17px;
}

.modal-header .closeBtn {
  right: 17px;
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 30px;
  padding-bottom: 0;
  max-height: inherit;
  overflow: hidden;
  overflow-y: scroll;
  justify-content: space-between;
}

.modal-thumbnail {
  width: 250px;
  height: 125px;
  background-color: grey;
  margin-bottom: 30px;
  cursor: pointer;
}

.modal-naming-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 24px 0;
}

.modal-naming-content legend {
  color: #808e9b;
  font-size: 12px;
}

.modal-naming-content fieldset {
  border: 1px solid black;
  border-radius: 4px;
}

.modal-naming-content input {
  border: none;
  width: 400px;
  outline: none;
  font-size: 16px;
  color: #1e272e;
}

.modal-naming-content p {
  font-size: 12px;
  color: red;
}

.modal-naming-content button {
  color: white;
  background: black;
  border: none;
  padding: 10px 40px;
  border-radius: 4px;
  margin-top: 38px;
  outline: none;
  cursor: pointer;
}

.modal-thumbnail img {
  object-fit: cover;
  width: inherit;
  height: inherit;
}

.ui.secondary.pointing.menu .item {
  border-bottom: 4px solid transparent;
}

.ui.secondary.pointing.menu .item.active,
.ui.secondary.pointing.menu .active.item:hover {
  border-bottom: 4px solid #ffce01;
}

.bottom-tray-title {
  font-size: 14px !important;
  color: rgba(30, 39, 46, 1) !important;
  font-weight: 600 !important;
  /* font-family: "IBM Plex Sans semi" */
}

.list-no-hotspots {
  margin-left: 85% !important;
}
.hotspot-icon-bottom {
  margin-right: 12px !important;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.cardd {
  flex: 0 0 auto;
  width: 200px;
  height: 150px;
}

.MuiPaper-rounded-135 {
  border-radius: 0 !important;
}

