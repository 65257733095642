* {
  box-sizing: border-box;
}

.category-card {
  position: absolute;
  width: 70vw;
  height: 62vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e2e6ea;
  border-radius: 5px 5px 0 0;
}

.category-card-header {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  background-color: black;
  padding: 15px 0;
  font-size: 20px;
  border-radius: 5px 5px 0 0;
}

.category-thumbnail-group {
  display: flex;
  padding: 0 70px;
  padding-top: 91px;
  /* padding-bottom: 85px; */
  flex-wrap: wrap;
  height: inherit;
  overflow: hidden;
  overflow-y: scroll;
  align-content: flex-start;
}

.category-addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 200px;
  border-radius: 4px;
  background-color: black;
  cursor: pointer;
  border: none;
}

.category-addBtn img {
  display: block;
}

.category-thumbnail {
  /* display: flex; */
  position: relative;
  height: 100px;
  width: 200px;
  border-radius: 4px;
  background-color: lightgrey;
  margin-left: 24px;
  margin-bottom: 28px;
  align-items: flex-end;
}

.category-thumbnail > img {
  object-fit: cover;
  width: inherit;
  height: inherit;
}

.category-thumbnail-group > *:nth-child(4n + 1) {
  margin-left: 0;
}

.thumbnail-tag {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 14px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.thumbnail-tag p {
  margin: 0;
  font-weight: 600;
  color: white;
}

.thumbnail-tag button {
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.category-card-footer {
  display: flex;
  background-color: white;
  justify-content: space-between;
  padding: 20px 100px;
  align-items: center;
  border-radius: 0 0 5px 5px;
}

.category-card-footer p {
  margin: 0;
}

.category-card-footer button {
  background-color: black;
  color: white;
  font-weight: 600;
  padding: 8px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.modal-card {
  position: relative;
  width: 600px;
  max-height: 70vh;
  background-color: white;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  align-items: center;
  background-color: white;
  z-index: 9;
  border-radius: 4px 4px 0 0;
}

.modal-header h2 {
  font-size: 20px;
  padding: 18px 0 9px 0;
  margin: 0;
}

.modal-header button {
  display: block;
  position: absolute;
  border: none;
  margin: 0;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  outline: none;
}

.modal-header .backBtn {
  left: 17px;
}

.modal-header .closeBtn {
  right: 17px;
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 30px;
  padding-bottom: 0;
  max-height: inherit;
  overflow: hidden;
  overflow-y: scroll;
  justify-content: space-between;
}

.modal-thumbnail {
  width: 250px;
  height: 125px;
  background-color: grey;
  margin-bottom: 30px;
  cursor: pointer;
}

.modal-naming-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 24px 0;
}

.modal-naming-content legend {
  color: #808e9b;
  font-size: 12px;
}

.modal-naming-content fieldset {
  border: 1px solid black;
  border-radius: 4px;
}

.modal-naming-content input {
  border: none;
  width: 400px;
  outline: none;
  font-size: 16px;
  color: #1e272e;
}

.modal-naming-content p {
  font-size: 12px;
  color: red;
}

.modal-naming-content button {
  color: white;
  background: black;
  border: none;
  padding: 10px 40px;
  border-radius: 4px;
  margin-top: 38px;
  outline: none;
  cursor: pointer;
}

.modal-thumbnail img {
  object-fit: cover;
  width: inherit;
  height: inherit;
}
