.activation-page-width-height{
    width: 100vw;
    height: 100vh;
    display: table;
}
.activation-content-align{
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}
.activation-font-head{
    font-size: 24;
    font-weight: 600;
}
.activation-font{
    margin-top: 12;
    font-size: 16;
}